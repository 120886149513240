import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import { CountUp } from "./plugins/countUp.js";

AOS.init({
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,
  disableMutationObserver: false,
  duration: 500,
  easing: "ease",
  once: true
});

const header = document.getElementsByClassName(`header`)[0];
const burger = document.getElementsByClassName(`header__burger`)[0];

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.onresize = function () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let prevScrollPos = window.pageYOffset;

if (window.scrollY !== 0) {
  header.classList.add("scrolled");
}

window.onscroll = () => {
  const currentScrollPos = window.pageYOffset;
  const headerHeight = header.scrollHeight;

  if (window.scrollY > 0) {
    if (prevScrollPos > currentScrollPos) {
      header.style.top = 0;
      header.classList.add("scrolled");
    } else {
      header.style.top = `-${headerHeight}px`;
    }
  }

  if (burger.classList.contains('active')) {
    header.classList.remove("scrolled");
  } else {
    header.classList.add("scrolled");
  }

  if (window.scrollY === 0) {
    header.classList.remove("scrolled");
  }

  prevScrollPos = currentScrollPos;
};

// Header scroll
// const scrollContainer = () => {
//   return document.documentElement || document.body;
// };
//
// document.addEventListener("scroll", () => {
//   if (scrollContainer().scrollTop > 0) {
//     header.classList.add("scrolled");
//   } else if (scrollContainer().scrollTop == 0) {
//     header.classList.remove("scrolled");
//   }
// });






// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");

    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");

  if (window.scrollY !== 0) {
    $(".header").toggleClass("scrolled");
  }


  burger.classList.contains("active")
      ? (burger.textContent = "Close")
      : (burger.textContent = "Menu");

  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar .cont"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

// Count up Section
const statsSection = document.querySelector(`.stats`);
if (statsSection) {
  const countsArr = [...document.getElementsByClassName(`stats-count-up`)];
  const defaultOptions = {
    separator: '',
    enableScrollSpy: true,
    scrollSpyRunOnce: true,
  };

  countsArr.forEach(elem => {
    let html = elem.innerHTML;
    const number = html.match('\\d+')[0];
    let prefix = '';

    if (html.indexOf(number) > 0) {
      prefix = html.slice(0, html.indexOf(number));
      html = html.replace(prefix, '');
    }

    const suffix = html.replace(number, '');

    let optionsFromDataAttr = $(elem).data();
    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    optionsFromDataAttr.prefix = prefix;
    optionsFromDataAttr.suffix = suffix;

    new CountUp(elem.id, number, Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr));
  })
}

// Talent Solutions Tabs
const tabsSection = document.querySelector('.talent-solutions');
if (tabsSection) {
  const tabs = tabsSection.querySelectorAll('.tabs-btns__item'), // Tabs list
      tabsParent = tabsSection.querySelector('.tabs-btns'), // Parent of tabs list
      tabsContent = tabsSection.querySelectorAll('.tabs-images__item'); // Content List

  // Hide items Function
  function hideItems() {
    tabsContent.forEach(item => {
      item.classList.remove('active');
    });

    tabs.forEach(item => {
      item.classList.remove('active');

      const tabsDesc = item.querySelector('.tabs-btns__item-content');
      tabsDesc.style.height = 0;
    });
  }

  // Show items Function
  function showItems(i = 0) {
    tabsContent[i].classList.add('active');
    tabs[i].classList.add('active');

    const tabsDesc = tabs[i].querySelector('.tabs-btns__item-content');
    tabsDesc.style.height = tabsDesc.scrollHeight + 'px'
  }

  hideItems();
  showItems();

  // Event delegation
  tabsParent.addEventListener('click', (event) => {
    const target = event.target;

    if (target && target.classList.contains('tabs-btns__item')) {
      tabs.forEach((item,i) => {
        if (target == item) {
          hideItems();
          showItems(i);
        }
      })
    }
  })
}





